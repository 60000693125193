export const Bio = {
  name: "Pratik Sawant",
  roles: [
    "Full Stack Developer",
    "UI/UX Designer",
    "Graphic Designer",
  ],
  description:
    "Experienced and PG-DAC certified web developer proficient in web design and development, equipped with advanced technological skills. Worked as an Executive Web Developer and Graphic Designer at TreatGlobal PVT Ltd, where I honed my expertise in crafting innovative digital solutions. I am now seeking new opportunities withinthe IT sector to further leverage my skills and contribute to impactful web development projects.",
  resume:
    "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/Pratik%20Sawant%20Full%20Stack%20Developer%20.pdf?alt=media&token=602537c6-1a9f-409b-b517-d884933e986f",
  linkedin: "https://www.linkedin.com/in/pratik-sawant-web-developer/",
  email: "sawantpratik1099@gmail.com",

  github : "https://github.com/pratiks1010"

};

export const skills = [
  
  {
    title: "Frontend",
    skills: [
      {
        name: "React Js",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/react.png?alt=media&token=5061034e-5010-41fc-a5bb-2deaf58293da",
      },
     
      {
        name: "HTML",
        image: "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/html.png?alt=media&token=9c276454-8cdc-4144-8158-e30733209d93",
      },
      {
        name: "CSS",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/css.png?alt=media&token=fcbad00f-74a8-48ad-a9dd-2500401970a8",
      },
      {
        name: "JavaScript",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/javascript.png?alt=media&token=756273aa-4511-4180-85f0-abe85b1ee74f",
      },
      {
        name: "Bootstrap",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/bootstrap.png?alt=media&token=e48a5f3a-eda5-4f41-959a-554c40d339bf",
      },
      {
        name: "ASP.NET MVC",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/aspmvc.jpg?alt=media&token=a168c116-58c7-49db-94e5-61074638c9fb",
      },
     


    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Node Js",
        image: "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/nextjs.png?alt=media&token=6f4c6bee-7fc9-40d8-a09f-40969d9eb674",
      },
      {
        name: "Express Js",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/express.png?alt=media&token=cd359ffa-b2d8-4bd2-8e57-a58c8491e7d8",
      },
      {
        name: "Spring Boot",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/spring%20boot.png?alt=media&token=b81bd744-3e4e-4e50-8788-64c88f1d2eca",
      },
      {
        name: "ASP.NET Web API",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/Web%20Api.jpg?alt=media&token=6158fb39-3a03-4950-83a7-8d42c9fa570c",
      },


      {
        name: "MySQL",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/mysql.png?alt=media&token=54b3d91c-cc8f-4577-b227-0eede258bb6a",
      },

      {
        name: "MongoDB",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg",
      },

    ],
  },
  {
    title: "UI/UX Design",
    skills: [
      {
        name: "Adobe Xd",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/xd.png?alt=media&token=d7419eb5-2459-4042-b0c6-ac49e9599b6a",
      },
      {
        name: "Figma",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/figma.png?alt=media&token=8b183ed8-c0da-4f7a-ad9a-678187c4466a",
      },
      {
        name: "Canva",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/canva.png?alt=media&token=9b59c002-4f76-4443-b36a-6b66ee19693f",
      },
      {
        name: "Photoshop",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/photoshop.png?alt=media&token=dcf91f3f-e2ee-4e56-8213-75123a4a5d42",
      },
      {
        name: "Adobe Illustrator",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/illustrator.png?alt=media&token=8464281d-7555-4da3-ad1b-7ee6d9a4df0d",
      },
      {
        name: "Wordpress",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/wordpress.png?alt=media&token=6908c51d-71b6-4edb-8145-8318e03ce00e",
      },
    ],
  },
  {
    title: "Tools",
    skills: [
      {
        name: "Git",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/git.png?alt=media&token=b1f57f9a-4b47-4dd8-980f-deb8f03bd0e3",
      },
      {
        name: "GitHub",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/github.png?alt=media&token=074aeaf6-857d-44eb-bc1b-3f4b311a01e9",
      },
      {
        name: "Docker",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/docker-mark-blue.png?alt=media&token=2a2dc954-1722-42b1-b8e3-fa4d708c4732",
      },

      {
        name: "VS Code",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/visual-studio.png?alt=media&token=903689e5-8419-4f65-84d3-2a5dbd42eeb1",
      },
      {
        name: "Postman",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/postman-icon.png?alt=media&token=f09d6748-f714-40db-957c-3fd5abd785c2",
      },
      {
        name: "Swagger",
        image:
          "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/Swagger-logo.png?alt=media&token=1a28b7a5-564b-4126-9474-ce114737954e",
      },


    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/treatglobal_logo.jpg?alt=media&token=8bddb9be-b7b6-4d9c-a8f8-8681abb408b4",
    role: "Executive Web Developer and Graphic Designer",
    company: "TreatGlobal PVT Ltd",
    date: "Present",
    desc: "Working as Web Developer and Graphic Designer ",
    skills: [
      "Reactjs",
      "HTML",
      "CSS",
      "Bootstrap",
      "JavaScript",
      "Springboot",
      "Photoshop",
      "Figma",
      "Adobe XD",
      " Next Js","WordPress","ASP.NET MVCASP","ASP.NET Web APIs"
    ],

  },





];

export const education = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/iacsd.png?alt=media&token=9a8bdbff-138f-4971-bd38-c5dca1946779",
    school: "Institute for Advanced Computing & Software Development",
    date: "March 2023 - Sep 2023",
    grade: "70.50%",
    desc: " completion of the Advanced Computing program and Software Development at #IACSD Akurdi, Pune, mastering Java, .NET (C#), SQL, MySQL, React, and MongoDB. Equipped with practical hands-on experience and a thirst for innovation, I'm prepared to tackle new challenges and contribute to cutting-edge projects. Grateful for the transformative journey at #IACSD, I'm eagerly anticipating the next chapter of professional growth.",
    degree: "PG Diploma in Advanced Computing",
  },
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/925837630s.jpg?alt=media&token=86759709-5c2c-4e9a-b8df-403e15f0aef7",
    school: "Sanjay Ghodawat Institutes Atigre Kohlapur",
    date: "June 2017 - Apr 2022",
    grade: "73.3%",
    desc: "completion of my Bachelor of Technology degree in Mechanical Engineering. With a solid foundation in Mechanical Engineering principles",
    degree: "B-Tech in Mechanical Engineering (Shivaji University Kohlapur)",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/12th.png?alt=media&token=ddfefdcb-cf72-4bff-9ef9-f04729269344",
    school: "Shanti English Medium School And Jr College, Solapur, Maharashtra 413004",
    date: "Apr 2015 - Apr 2017",
    grade: "54.6%",
    desc: "I completed my class 12th education at Shanti English Medium School And Jr College where I studied Science with Computer Application.",
    degree: "12th Class in Science",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/svp.jpg?alt=media&token=78a1e8c3-caf7-4d37-a8f5-3cc53b42cc36",
    school: "Swami Vivekanand Prashala jule Solapur",
    date: "10th Class",
    grade: "78%",
    desc: "I completed my class 10 education at Swami Vivekanand Prashala, Jule Solapur where I Completed 10th std Education.",
    degree: "10th Std",
  },
];

export const projects = [

  {
    id: 15,
    title: ".NET 8 Web API & Entity Framework 🚀 Full CRUD Application ",
    date: "20 Aug 2024",
    description:
      "🚀 Exciting News! I've just uploaded, .NET 8 Web API & Entity Framework simple all Crud Operation🎉",
    image:
      "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/ASP%20.Net%20Web%20API.jpg?alt=media&token=5af2b48e-43ce-46c9-aa22-dabe52a9e754",
    tags: [
      ".NET 8 Web API",
      "Entity Framework Core",
      "Entity Framework Core tools ",
      "Visual Studio IDE",

    ],
    category: "Frentend Technologies",
    github: "https://github.com/pratiks1010/DotNet-Web-API.git",
    webapp: "https://github.com/pratiks1010/DotNet-Web-API.git",
  },
  {
    id: 16,
    title: ".NET Web MVC Application, with the .NET framework.  ",
    date: "14 Aug 2023",
    description:
      "🚀 Exciting News! I've just uploaded .NET Web Application, with the .NET framework. simple all Crud Operation🎉",
    image:
      "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/Asp%20.net%20Web%20MVC.jpg?alt=media&token=50bb47ce-f28e-4895-8ab0-28ecccb564ec",
    tags: [
      ".NET framework",
      "Entity Framework Core",
      "Entity Framework Core tools ",
      "Visual Studio IDE",

    ],
    category: "Frentend Technologies",
    github: "https://github.com/pratiks1010/.NETWebApplication.git",
    webapp: "https://www.linkedin.com/posts/pratik-sawant-web-developer_dotnet-webdevelopment-fullstack-activity-7096797391147737089-bXoS?utm_source=share&utm_medium=member_desktop",
  },
  {
    id: 11,
    title: "Personal Portfolio Website  ",
    date: "16 May 2024",
    description:
      "👋 Excited to share my latest project - a Personal Portfolio Website built with React and CSS! 💻",
    image:
      "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/5.jpg?alt=media&token=5e39b6ef-da5b-48c8-a4b7-f2302ea6a702",
    tags: [
      "Reactjs",
      "CSS",
      "React-icons",
      "Firebase",

    ],
    category: "Frentend Technologies",
    github: "https://github.com/pratiks1010/Personal-Portfolio-Website",
    webapp: "https://www.linkedin.com/posts/pratik-sawant-web-developer_react-css-webdevelopment-activity-7195775369537363968-x97H?utm_source=share&utm_medium=member_desktop",
  },
  {
    id: 10,
    title: "A Voting Application Back-end API ",
    date: "10 May 2024",
    description:
      "This voting application allows users to cast their votes for a predefined set of candidates, ensuring a smooth and efficient voting process.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/4.jpg?alt=media&token=6345c248-6b36-4aef-97c7-e7233408a64e",
    tags: [
      "Nodejs",
      "Expressjs",
      "mongodb Atlas",
      "JWT",
      "Postman",

    ],
    category: "Full Stack Application",
    github: "https://www.linkedin.com/posts/pratik-sawant-726672223_carrental-uiuxdesign-websitelaunch-activity-7162324224957706240-bazw?utm_source=share&utm_medium=member_desktop",
    webapp: "https://www.behance.net/gallery/191247765/Rental-car-website",
  },
  {
    id: 9,
    title: "Ui UX Design of Rental Car Platform ",
    date: "06 february 2024",
    description:
      "latest project: a sleek and user-friendly Ui UX Design of rental car platform designed with your convenience in mind. Browse through our diverse selection of luxury vehicles, enjoy seamless booking, and benefit from 24/7 customer support. Your next adventure starts here Explore our user-friendly website now for hassle-free booking and unparalleled service. Visit my Behance profile for an in-depth look at our innovative UI/UX design.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/Rental%20Car%20WebSite%20Page%201.jpg?alt=media&token=486f765b-6b5f-43fc-a2d5-9645d23fdb7a",
    tags: [
      "Adobe XD",
      "Photoshop",
      "Canva",
      "Figma",
      "flaticon",

    ],
    category: "UI UX",
    github: "https://github.com/pratiks1010/Voting-Application-REST-API-",
    webapp: "https://www.linkedin.com/pulse/introducing-my-latest-project-voting-application-pratik-sawant-wcgcf/?trackingId=8thPc5c7QteQnlx6kw9QbA%3D%3D",
  },
  {
    id: 0,
    title: "Food Delivery App",
    date: "16 february 2024",
    description:
      "latest UI/UX project 🎨✨- a sleek and user-friendly design for a food delivery app! 🚀 From seamless navigation to enticing visuals, every detail is crafted for a delightful dining experience. ",
    image:
      "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/CoverImage.jpg?alt=media&token=4948d075-7694-4cd6-a7ac-fc4ee71db9a4",
    tags: ["Adobe XD", "Photoshop", "Canva", "Figma", "flaticon"],
    category: "UI UX",
    github: "https://www.linkedin.com/posts/pratik-sawant-726672223_uiuxdesign-fooddeliveryapp-designexcellence-activity-7157235327600934912-Co9n?utm_source=share&utm_medium=member_desktop",
    webapp: "https://www.behance.net/gallery/190103003/food-delivery-app",
    
  },
  {
    id: 1,
    title: "Sneaker Store Website Home Page",
    date: "24 January 2024",
    description:
      "Excited to share my new sneaker store website! UI/UX Project 🚀 It's easy to use, looks great, and has cool features. You can easily find what you're looking for with the simple layout and search function. I added awesome pictures and videos with interesting info to make it more appealing. ",
    image:
      "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/shoeWebSite%20(page1).jpg?alt=media&token=97f87f83-1590-4a43-8b31-3fe96c670b39",
    tags: [
      "Adobe XD",
      "Photoshop",
      "Canva",
      "Figma",
      "flaticon",
      "Clean png",
    ],
    category: "UI UX",
    github: "https://www.linkedin.com/posts/pratik-sawant-726672223_ui-ux-webdesign-activity-7155602075245043712-dEE_?utm_source=share&utm_medium=member_desktop",
    webapp: "https://www.behance.net/gallery/189733061/sneaker-store-website",
  },
  {
    id: 2,
    title: "Pet Paradise (Pet Shop Web Application)",
    date: "10 Aug 2023 - 15 Sep 2023",
    description:
      "Pet Shop web application is to provide a digital platform designed to facilitate the buying, selling, and management of pets and pet-related products. It serves as an online marketplace where pet enthusiasts, pet owners, breeders, and pet product vendors can interact, showcase their offerings, and make transactions.The objective of the Pet-Shop Web Application is to create a user-friendly and effective platform for customers to easily browse and buy products related to pets. The application strives to ensure a smooth and hassle-free shopping journey, allowing users to discover an extensive variety of pet products and complete their purchases online with ease",
    image:
      "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/homepahe.png?alt=media&token=0df8aac5-b95d-42fa-9fb4-dc2a2f82b783",
    tags: ["ReactJs ", "Javascript", "Axios", "HTML5", "CSS", "Bootstrap 5", "Java", "Spring Boot", "REST Api", "JWT", "Spring Security"],
    category: "Full Stack Application",
    github: "https://github.com/pratiks1010/PetShop_WebApplication.git",

    member: [
      {
        name: "Pratik Sawant",
        img: "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/logo8.png?alt=media&token=a6ba294c-1b44-414b-b769-e3217ff7feda",
        linkedin: "https://www.linkedin.com/in/pratik-sawant-726672223/",
        github: "https://github.com/pratiks1010",
      },
      {
        name: "Uddesh Deshmukh",
        img: "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/uddesh.jpg?alt=media&token=5ea0d760-375b-4afc-a9ff-2779888baf03",
        linkedin: "https://www.linkedin.com/in/uddesh-deshmukh-073b97204",
        github: "http://github.com/UddeshDeshmukh21",
      },
    ],
  },

  {
    id: 10,
    title: "Crypto Currency App",
    date: "15 January 2024",
    description:
      "Crypto Currency App! 🌐💼 I've built a React app that provides real-time information about various cryptocurrencies. 📊💰 👉 Features:Search and explore different cryptocurrencies. Details include rank, name, symbol, market capitalization, and more.Clean and user-friendly interface.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/2.jpg?alt=media&token=09e783ff-64b8-48c0-82b7-3aba987a23c8",
    tags: ["ReactJS", "JavaScript", "Axios", "HTML5 ", "CSS", " Bootstrap 5", "Rest API"],
    category: "Frentend Technologies",
    github: "https://lnkd.in/dZBf76B2",
    webapp: "https://www.linkedin.com/posts/pratik-sawant-726672223_react-cryptocurrency-programming-activity-7153743121254604804-AiEM?utm_source=share&utm_medium=member_desktop",
  },

  {
    id: 7,
    title: "Pizza Paradise",
    date: "12 january 2024",
    description:
      "introduce my latest web project, Pizza Paradise! 🍕. Built with React.js and Bootstrap 5, Pizza Paradise is a responsive and delicious way to explore a mouthwatering pizza menu and place your order online. It's been a labor of love, and I'm excited to share it with you all.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/pizza%20paradise.png?alt=media&token=d4677a3d-aa73-4022-8f49-f9b70f3dbadf",
    tags: ["Reactjs", "JavaScript", "HTML5 ", "CSS", " Bootstrap 5"],
    category: "Frentend Technologies",
    github: "https://lnkd.in/dycJDdpu",
    webapp: "https://www.linkedin.com/posts/pratik-sawant-726672223_webdevelopment-reactjs-bootstrap5-activity-7117082962185105408-8raz?utm_source=share&utm_medium=member_desktop",
  },
  {
    id: 8,
    title: " Zomato-inspired Restaurant Web Application",
    date: "18 September 2023",
    description:
      " project: a Zomato-inspired restaurant web application built with React.js! 🍽️ 🔍 Project Highlights: Modern UI: Designed with user-friendly features and a clean interface for a seamless dining experience. Search and Filter: Easily discover restaurants by cuisine, location, and price range. Detailed Listings: Get all the information you need - from menu options to reviews. User Reviews: Leave your thoughts and read what others are saying about their dining experiences.Responsive: Accessible on all devices, so you can find your next meal on the go!",
    image:
      "https://firebasestorage.googleapis.com/v0/b/images-18f5e.appspot.com/o/zomato.png?alt=media&token=232bd934-39e3-44d3-8358-787147433d36",
    tags: ["Reactjs", "JavaScript", "HTML5 ", "CSS", " Bootstrap 5"],
    category: "Frentend Technologies",
    github: "https://lnkd.in/d29e_Nad",
    webapp: "https://www.linkedin.com/posts/pratik-sawant-726672223_reactjs-webdevelopment-restaurantapp-activity-7109811572176252928-4zYy?utm_source=share&utm_medium=member_desktop",
  },
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
